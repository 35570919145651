import { CSSObject } from 'styled-components'

import { SvgComponentProps } from '@app/components/svg-icons'
import * as icons from '@app/components/svg-icons/icons'

export type ButtonElementType = 'a' | 'button'
export type ButtonSize = 'x-large' | 'large' | 'medium' | 'small'
export type ButtonVariant =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'facebook'
	| 'whatsapp'
	| 'grey'

export interface ButtonProps {
	link?: boolean
	a?: boolean
	css?: CSSObject

	icon?: keyof typeof icons
	buttonSize?: ButtonSize // would have prefered to name it `size`, but that is a reserved prop name
	variant?: ButtonVariant
	outline?: boolean
}

export type ButtonPropsForAnchorElement = ButtonProps & React.HTMLProps<HTMLAnchorElement>
export type ButtonPropsForButtonElement = ButtonProps & React.HTMLProps<HTMLButtonElement>
export type ButtonPropsForLink = ButtonPropsForAnchorElement & {
	to?: string
}
export type AllButtonProps = ButtonPropsForAnchorElement &
	ButtonPropsForButtonElement &
	ButtonPropsForLink

export const getButtonIconProps = (icon: keyof typeof icons): SvgComponentProps => ({
	icon,
	fill: 'currentColor',
})
