import { CSSObject } from 'styled-components'

import { colors, themeColors, transition } from '@styleguide'

import { AllButtonProps, ButtonProps } from './button-types'

const buttonStyles: CSSObject = {
	cursor: 'pointer',
	display: 'inline-flex',
	alignItems: 'center',
	overflow: 'hidden',
	position: 'relative',
	userSelect: 'none',
	textDecoration: 'none',
	verticalAlign: 'middle',
	whiteSpace: 'normal',

	background: colors.PRIMARY,
	border: '1px transparent solid',
	borderRadius: 8,
	color: 'white',
	lineHeight: 1.6,
	textTransform: 'uppercase',
	transition: transition.config,
	transitionProperty: 'border-color, background-color',

	['> span']: {
		color: 'white',
		transition: transition.config,
		transitionProperty: 'color',
	},
}

const sizeStyles = ({ buttonSize }: ButtonProps): CSSObject => {
	switch (buttonSize) {
		case 'x-large':
			return {
				fontSize: '2.2rem',
				fontWeight: 500,
				padding: '1.15rem 2.6rem',
			}
		case 'large':
		default:
			return {
				fontSize: '1.8rem',
				fontWeight: 500,
				padding: '0.75rem 2.3rem',
			}
		case 'medium':
			return {
				fontSize: '1.4rem',
				fontWeight: 500,
				padding: '0.55rem 1.5rem',
			}
		case 'small':
			return {
				fontSize: '1.3rem',
				fontWeight: 500,
				padding: '0.4rem 1.5rem',
			}
	}
}

export interface ThemeColors {
	default: string
	hover: string
}
export const getVariantColors = ({ variant }: ButtonProps): CSSObject => {
	switch (variant) {
		case 'primary':
		default:
			return {
				background: colors.BLACK,
				['&:hover']: {
					background: colors.BLACK,
				},
			}
		case 'secondary':
			return {
				background: colors.BLACK,
				['&:hover']: {
					background: colors.BLACK,
				},
			}
		case 'tertiary':
			return {
				background: colors.BLACK,
				['&:hover']: {
					background: colors.BLACK,
				},
			}
		case 'facebook':
			return {
				background: colors.FACEBOOK,
				['&:hover']: {
					background: colors.FACEBOOK,
				},
			}
		case 'whatsapp':
			return {
				background: themeColors.finances.dark,
				['&:hover']: {
					background: themeColors.finances.dark,
				},
			}
		case 'grey':
			return {
				background: colors.GREY_40,
				['&:hover']: {
					background: colors.GREY_50,
				},
			}
	}
}

const outlineStyles = ({ outline }: ButtonProps) => {
	if (!outline) {
		return {}
	}

	return {
		background: 'transparent',
		border: '1px currentColor solid',

		['> span']: {
			color: 'currentColor',
		},

		['&:hover']: {
			background: 'currentColor',

			['> span']: {
				color: 'white',
			},
		},
	}
}

const disabledStyles = ({ disabled }: AllButtonProps) => {
	if (!disabled) {
		return {}
	}

	const state = {
		borderColor: colors.BLACK,
		backgroundColor: colors.BLACK,

		['> span']: {
			color: colors.BLACK,
		},
	}

	return {
		cursor: 'default',

		...state,
		['&:hover']: state,
		['&:active']: state,
	}
}

const stylesMap = {
	default: buttonStyles,
	size: sizeStyles,
	disabled: disabledStyles,
	outline: outlineStyles,
	variant: getVariantColors,
}

export default stylesMap
