import qs from 'query-string'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'

import {
	Button,
	DropdownCaret,
	Grid,
	HelmetData,
	Page,
	Pagination,
	Sidebar,
	SidebarContent,
	SidebarToggle,
	Small,
} from '@app/components'
// import { InspirationGame } from '@app/pages/list/components/InspirationGame'
import { Error404 } from '@app/pages/error/error-404'
import { ListSubmitSection } from '@app/pages/list/components/ListSubmitSection'
import {
	withContentStore,
	WithContentStoreProps,
	withModalStore,
	WithModalStoreProps,
} from '@app/stores'
import {
	AddItemFilterFunction,
	AddTagFilterFunction,
	CategoryFilter,
	ListFilters,
	MappedTag,
	RemoveIDFilterFunction,
	RemoveInstagramFilterFunction,
	RemoveTagFilterFunction,
	// SetInspirationGameFiltersFunction,
} from '@app/types'
import { CompassUtils, isBrowser, shuffle } from '@app/utils'
import { InspirationGameSet, ListResource, PageListResource } from '@compass/types'
import { colors, mixins, spacing } from '@styleguide'

import * as ListComponents from './components/List'

interface ListBaseProps {
	resource: PageListResource
	itemsPerPage?: number
	items?: ListResource[]
}

type ListProps = ListBaseProps &
	WithContentStoreProps &
	WithModalStoreProps &
	RouteComponentProps<any>

interface ListState {
	items?: ListResource[]
	filteredList?: ListResource[]
	amountOfItems: number
	list: ListResource[]
	page: number
	filters: ListFilters
	inspirationGame?: InspirationGameSet[]
	tags: MappedTag[]
	initiatives: CategoryFilter[]
	categories: CategoryFilter[]
	query: string
	isMobileOpen: boolean
	pagination: {
		page: number
		amountOfPages: number
	}
}

const ListHeader = styled.div({
	display: 'flex',
	flexDirection: 'row-reverse',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: spacing.xs,
	marginBottom: spacing.xs,
	paddingBottom: spacing.xs,
	borderBottom: `1px ${colors.GREY_20} solid`,

	[mixins.media.max('md')]: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
})

const MainTitle = styled.h1({
	marginBottom: spacing.sm,
})

const ItemWrapper = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	margin: `0 -${spacing.xs}px`,
})

export const GridItemWrapper = styled.div({
	width: '100%',
	marginBottom: spacing.md,
	padding: `0 ${spacing.xs}px`,

	...mixins.mediaStyles({
		md: {
			width: '50%',
		},
		lg: {
			width: '33.333333333333%',
		},
		xl: {
			width: '25%',
		},
		xxl: {
			width: '20%',
		},
	}),
})

const FiltersTitle = styled.h4({
	marginBottom: 5,

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})

const calculateAmountOfPages = (amountOfItems: number, itemsPerPage: number = 20): number => {
	return Math.ceil(amountOfItems / itemsPerPage)
}

export class PageListClass extends React.Component<
	RouteComponentProps<any> & ListProps,
	ListState
> {
	private scrollToElement: any

	public state: ListState = {
		amountOfItems: 0,
		page: 1,
		items: [],
		filteredList: [],
		list: this.getRandomizedList(),
		filters: {
			tags: [],
			initiatives: CompassUtils.list.getInitialInitiativeFilters(
				this.props.resource,
				this.props.location.pathname,
			),
			categories: CompassUtils.list.getInitialCategoryFilters(
				this.props.resource,
				this.props.location.pathname,
			),
			inspirationGame: [],
			id: Number(qs.parse(this.props.location.search).item) || undefined,
		},
		tags: [],
		inspirationGame: [],
		initiatives: [],
		categories: [],
		query: '',
		isMobileOpen: false,
		pagination: {
			page: 1,
			amountOfPages: calculateAmountOfPages(0, this.props.itemsPerPage || 20),
		},
	}

	private toggleSidebar = () => {
		this.setState({
			isMobileOpen: !this.state.isMobileOpen,
		})
	}

	public componentDidMount() {
		this.scrollToElement = require('scroll-to-element')

		const isModalOpen =
			new URLSearchParams(this.props.location.search).get('modal') === 'submit-organisation'
		if (isModalOpen) {
			this.props.modalStore.openModal('listSubmit')
		}
	}

	public componentDidUpdate(prevProps: ListProps) {
		if (this.props.location.search !== prevProps.location.search) {
			this.setState({
				filters: {
					...this.state.filters,
					id: Number(qs.parse(this.props.location.search).item) || undefined,
				},
			})
		}
	}

	public setPage(page: number) {
		this.setState({ page })
	}

	public updatePagedResults = (page: number) => {
		this.setPage(page)
		this.scrollToElement(document.body, {
			offset: 0,
			ease: 'inOutQuad',
			duration: 500,
		})
	}

	public static getDerivedStateFromProps(props: ListProps, state: ListState) {
		const { resource } = props
		const filteredList = PageListClass.getFilteredItems(state)
		const items = PageListClass.getPagedItems(state, filteredList)

		return {
			amountOfItems: filteredList.length,
			filteredList,
			items,
			tags: CompassUtils.list.getTagsList(resource),
			inspirationGame: resource.inspiration_game,
			initiatives: CompassUtils.list.getInitiativeFilters(resource),
			categories: CompassUtils.list.getCategoryFilters(resource),
			pagination: {
				...state.pagination,
				amountOfPages: calculateAmountOfPages(
					filteredList.length,
					props.itemsPerPage || 20,
				),
			},
		}
	}

	public static getFilteredItems(state: ListState): ListResource[] {
		const {
			list,
			filters = {
				tags: [],
				inspirationGame: [],
				initiatives: [],
				categories: [],
				id: undefined,
			},
		} = state

		return list
            .filter((item: ListResource) => {
                const hasTitle = !!item.title && item.title.trim() !== '';
                const hasContent = !!item.content && item.content.trim() !== '';
                const hasContactDetails = !!(item.list_item && (
                    item.list_item.email ||
                    item.list_item.phone ||
                    item.list_item.website ||
                    item.list_item.facebook ||
                    item.list_item.instagram
                ));

                return hasTitle || hasContent || hasContactDetails;
            })
			.filter((item: ListResource) => {
				return !filters.id || item.id === filters.id
			})
			.filter((item: ListResource) => {
				return !filters.tags.length || CompassUtils.list.itemHasTags(item, filters.tags)
			})
			.filter((item: ListResource) => {
				return (
					!filters.initiatives.length ||
					(item.list_item.initiative &&
						filters.initiatives.some(i => i.name === item.list_item.initiative))
				)
			})
			.filter((item: ListResource) => {
				return (
					!filters.categories.length ||
					(item.categories &&
						item.categories.some(category =>
							filters.categories.some(c => c.name === category),
						))
				)
			})
			.filter((item: ListResource) => {
				return (
					!filters.inspirationGame ||
					CompassUtils.list.itemHasIGFilters(item, filters.inspirationGame)
				)
			})
	}

	private getRandomizedList() {
		return !isBrowser ? this.props.resource.list : shuffle([...this.props.resource.list])
	}

	public static getPagedItems(state: ListState, items: ListResource[]): ListResource[] {
		const { page } = state
		const start = (page - 1) * 20
		const end = page * 20

		return items.filter((_, index: number) => {
			return index >= start && index < end
		})
	}

	public updateCurrentPage = (page: number) => {
		this.setState({ page })
	}

	private onRemoveInstagramFilter: RemoveInstagramFilterFunction = choice => {
		this.setState({
			page: 1,
			filters: {
				...this.state.filters,
				inspirationGame: this.state.filters.inspirationGame.filter(
					question => question.question !== choice.question,
				),
			},
		})
	}

	private onRemoveIDFilter: RemoveIDFilterFunction = () => {
		this.setState({
			filters: {
				...this.state.filters,
				id: undefined,
			},
		})
	}

	private onAddItemFilter: AddItemFilterFunction = (id: number) => {
		this.setState({
			filters: {
				...this.state.filters,
				id,
			},
		})
	}

	private onAddTagFilter: AddTagFilterFunction = tag => {
		if (this.state.filters.tags.includes(tag)) {
			return
		}

		this.setState({
			page: 1,
			filters: {
				...this.state.filters,
				tags: this.state.filters.tags.concat(tag),
			},
		})
	}

	private onRemoveTagFilter: RemoveTagFilterFunction = tag => {
		this.setState({
			page: 1,
			filters: {
				...this.state.filters,
				tags: this.state.filters.tags.filter(t => t !== tag),
			},
		})
	}

	private isInvalidUrl() {
		const presetCategory = this.props.location.pathname.split('/')[2]

		return (
			presetCategory &&
			(!this.state.filters.categories.length && !this.state.filters.initiatives.length)
		)
	}

	public onUpdateFilters = (selectedInitiatives: string[], selectedCategories: string[]) => {
		this.setState({
			page: 1,
			filters: {
				...this.state.filters,
				initiatives: this.state.initiatives.filter(i =>
					selectedInitiatives.includes(i.name),
				),
				categories: this.state.categories.filter(i => selectedCategories.includes(i.name)),
			},
		})
	}
	private openListSubmitModal = () => {
		this.props.modalStore.openModal('listSubmit')
		this.props.history.push({
			search: '?modal=submit-organisation',
		})
	}

	public render() {
		if (this.isInvalidUrl()) {
			return <Error404 />
		}

		const { itemsPerPage = 20, resource } = this.props
		const { title, list_type, /* inspiration_game, */ seo } = resource
		const {
			items = [],
			amountOfItems,
			filters,
			initiatives,
			categories,
			inspirationGame = [],
			page,
			tags = [],
			isMobileOpen,
		} = this.state

		const hasActiveList = resource.list && resource.list.length > 0
		// const hasInspirationGame = !!resource.inspiration_game

		return (
			<Page minHeight={null}>
				<HelmetData title={title} seo={seo} />
				<Grid.Container>
					<Grid.Row>
						<Grid.LeftPaddedColumn xs={16} md={4} xl={3}>
							<Sidebar css={{ height: 'auto' }} isOpen={isMobileOpen}>
								<SidebarToggle isOpen={isMobileOpen} onClick={this.toggleSidebar}>
									<DropdownCaret isActive={isMobileOpen} />
									Options
								</SidebarToggle>
								<SidebarContent>
									<FiltersTitle className="u-theme-text">Filters:</FiltersTitle>
									<ListComponents.FiltersActive
										filters={filters}
										onRemoveInstagramFilter={this.onRemoveInstagramFilter}
										onRemoveTagFilter={this.onRemoveTagFilter}
										onRemoveIDFilter={this.onRemoveIDFilter}
										items={resource.list}
										inspirationGame={inspirationGame}
									/>
									<ListComponents.Search
										tagsFilters={tags}
										items={resource.list}
										onAddTagFilter={this.onAddTagFilter}
										onAddItemFilter={this.onAddItemFilter}
									/>
									<ListComponents.ListCategoriesForm
										initiatives={initiatives}
										selectedInitiatives={filters.initiatives}
										categories={categories}
										selectedCategories={filters.categories}
										onUpdateFilters={this.onUpdateFilters}
									/>

									<Small css={{ marginTop: spacing.md }}>
										Want to submit your {list_type} organisation?
									</Small>
									<Button
										css={{ marginTop: 5 }}
										className="u-theme-background"
										buttonSize="small"
										onClick={this.openListSubmitModal}>
										Fill in the form
									</Button>
								</SidebarContent>
							</Sidebar>
						</Grid.LeftPaddedColumn>
						<Grid.PaddedColumn xs={16} md={{ size: 12 }} xl={{ size: 13 }}>
							{/* {hasInspirationGame && (
								<InspirationGame
									isScrolledPast={true}
									inspirationGame={inspiration_game}
									type={list_type}
									onSetInspirationGameFilters={this.onSetInspirationGameFilters}
								/>
							)} */}
							<MainTitle className="u-theme-text">{title}</MainTitle>

							{isBrowser && hasActiveList && (
								<>
									<ListHeader>
										<div />
										<Pagination
											updateCurrentPage={this.updateCurrentPage}
											itemsPerPage={itemsPerPage}
											amountOfItems={amountOfItems}
											amountOfPages={this.state.pagination.amountOfPages}
											page={page}
										/>
										<ListComponents.ResultCount
											page={page}
											amountOfItems={amountOfItems}
										/>
									</ListHeader>
									<ListComponents.Wrapper>
										<ItemWrapper>
											{items.length > 0 &&
												items.map((item: ListResource) => (
													<GridItemWrapper key={item.id}>
														<ListComponents.Item
															item={item}
															onAddTagFilter={this.onAddTagFilter}
														/>
													</GridItemWrapper>
												))}
										</ItemWrapper>
									</ListComponents.Wrapper>
								</>
							)}
						</Grid.PaddedColumn>
					</Grid.Row>
				</Grid.Container>
				<ListSubmitSection type={list_type} />
			</Page>
		)
	}
}

export const PageList = compose<ListProps, ListBaseProps>(
	withContentStore,
	withModalStore,
	withRouter,
)(PageListClass)
