import React from 'react'
import styled from 'styled-components'

import { ContactDetails, ImageFlipCard } from '@app/components'
import { AddTagFilterFunction } from '@app/types'
import { ListResource } from '@compass/types'
import { colors, shadows, spacing } from '@styleguide'

interface ListItemProps {
	item: ListResource
	onAddTagFilter: AddTagFilterFunction
}

const Title = styled.h3({
	fontSize: '2.4rem',
	marginBottom: spacing.xs,
	textAlign: 'center',
	padding: `0 ${spacing.xs}px`,
})

const Wrapper = styled.div({
	position: 'relative',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
})

const ImageWrapper = styled.div({
	position: 'relative',
	width: 200,
	height: 200,
	borderRadius: '50%',
	background: colors.WHITE,
	border: `10px ${colors.WHITE} solid`,
	marginBottom: -80,
	overflow: 'hidden',
	boxShadow: shadows[7],
	zIndex: 1,
	flexShrink: 0,
})

const Content = styled.div({
	width: '100%',
	background: colors.WHITE,
	boxShadow: shadows[7],
	borderRadius: 8,
	padding: `100px ${spacing.sm}px ${spacing.xs}px`,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
})

const RichText = styled.div({
	fontSize: '1.4rem',
	marginBottom: spacing.xs,
	padding: `0 ${spacing.xs}px`,
	color: colors.GREY_50,
})

export class Item extends React.Component<ListItemProps> {
	public render() {
		const { item } = this.props

		return (
			<Wrapper>
				<ImageWrapper className="u-theme-background">
					{item.list_item.logo && item.list_item.icon && (
						<ImageFlipCard back={item.list_item.logo} front={item.list_item.icon} />
					)}
				</ImageWrapper>
				<Content>
					<Title className="u-theme-text">{item.title}</Title>
					{item.content && (
						<RichText dangerouslySetInnerHTML={{ __html: item.content }} />
					)}
					<div css={{ marginTop: 'auto', marginBottom: spacing.xs }}>
						<ContactDetails
							email={item.list_item.email}
							phone={item.list_item.phone}
							website={item.list_item.website}
							facebook={item.list_item.facebook}
							instagram={item.list_item.instagram}
						/>
					</div>
				</Content>
			</Wrapper>
		)
	}
}
