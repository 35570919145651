import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Button } from '@app/components/button'
import { CharacterCounter } from '@app/components/character-counter'
import { FileInput, FilePreview, TextInput } from '@app/components/form-elements'
import { Grid } from '@app/components/grid'
import * as validations from '@app/utils/validation'
import { ListSubmitFormInput } from '@compass/types'
import { spacing } from '@styleguide'

import * as SharedFormComponents from './_shared'
import { ContactTextInput } from './submit-list-item-form-components'

interface ChangeShippingAddressFormProps {
	onSubmit: (values: ListSubmitFormInput) => void
}

const Div = styled.div({
	marginBottom: spacing.sm,
})

export class SubmitListItemForm extends React.Component<ChangeShippingAddressFormProps> {
	private onSubmit = (values: ListSubmitFormInput) => {
		if (this.props.onSubmit) {
			this.props.onSubmit(values)
		}
	}

	public render() {
		return (
			<Form
				initialValues={{
					action: 'list-submit',
				}}
				onSubmit={this.onSubmit}>
				{({ handleSubmit }) => (
					<SharedFormComponents.Form onSubmit={handleSubmit}>
						<Field
							name="name"
							validate={validations.required}
							render={({ input, meta }) => (
								<TextInput
									{...input}
									labelText="Organisation"
									placeholder="Fill in the name of your organisation"
									hasError={meta.touched && meta.error}
									errorText={meta.error}
								/>
							)}
						/>
						<Field
							name="description"
							validate={validations.compose(
								validations.required,
								validations.maxCharCount(300),
							)}
							render={({ input, meta }) => (
								<CharacterCounter value={input.value} max={300}>
									<TextInput
										{...input}
										textarea
										labelText="Description"
										placeholder="Write a descriptive text about your organisation"
										hasError={meta.touched && meta.error}
										errorText={meta.error}
									/>
								</CharacterCounter>
							)}
						/>
						<TextInput.Label>Provide contact details</TextInput.Label>
						<Grid.Row>
							<Grid.Column xs={16} md={8}>
								<Div className="u-theme-text">
									<Field
										name="website"
										validate={validations.required}
										render={({ input, meta }) => (
											<ContactTextInput
												type="website"
												{...input}
												placeholder="Website URL"
												hasError={meta.touched && meta.error}
												errorText={meta.error}
											/>
										)}
									/>
									<Field
										name="email"
										validate={validations.email}
										render={({ input, meta }) => (
											<ContactTextInput
												type="email"
												{...input}
												placeholder="Email address"
												hasError={meta.touched && meta.error}
												errorText={meta.error}
											/>
										)}
									/>
									<Field
										name="phone"
										render={({ input }) => (
											<ContactTextInput
												type="phone"
												{...input}
												placeholder="Phone number"
											/>
										)}
									/>
								</Div>
							</Grid.Column>
							<Grid.Column xs={16} md={8}>
								<Field
									name="facebook"
									render={({ input }) => (
										<ContactTextInput
											type="facebook"
											{...input}
											placeholder="Facebook page"
										/>
									)}
								/>
								<Field
									name="instagram"
									render={({ input }) => (
										<ContactTextInput
											type="instagram"
											{...input}
											placeholder="Instagram page"
										/>
									)}
								/>
							</Grid.Column>
							<Grid.Column xs={16} md={8}>
								<Field
									name="logo"
									render={({ input }) => (
										<FileInput
											{...input}
											onPreview={(file: FilePreview) => {
												input.onChange(file)
											}}
											accept="image/*"
											labelText="Logo"
										/>
									)}
								/>
							</Grid.Column>
						</Grid.Row>
						<SharedFormComponents.ButtonBar css={{ marginTop: spacing.md }}>
							<Button type="submit" buttonSize="large">
								Submit
							</Button>
						</SharedFormComponents.ButtonBar>
					</SharedFormComponents.Form>
				)}
			</Form>
		)
	}
}
