import copy from 'copy-to-clipboard'
import React from 'react'
import styled from 'styled-components'

import * as IconNavigationComponents from '@app/components//icon-navigation/icon-navigation-components'
import { SvgIcon } from '@app/components/svg-icons'
import * as iconset from '@app/components/svg-icons/icons'
import { colors } from '@styleguide'
import { Tooltip, TooltipInner } from '../tooltip'

interface Icons {
	[key: string]: keyof typeof iconset
}
export const icons: Icons = {
	email: 'contact',
	phone: 'phone',
	website: 'website',
	facebook: 'facebook',
	instagram: 'instagram'
}
export interface ContactDetailsProps {
	website?: string
	instagram?: string
	email?: string
	phone?: string
	facebook?: string
}

const ContactDetailsGlam = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
})

const ContactDetailsButtonWrapper = styled.div({
	['&:not(:first-child)']: {
		marginLeft: 5,
	},

	['&:not(:last-child)']: {
		marginRight: 5,
	},
})

const mapHref = (type: keyof ContactDetailsProps, value: string) => {
	switch (type) {
		case 'email':
			return `mailto:${value}`
		case 'phone':
			return `tel:${value}`
		default:
			return value
	}
}

const copyToClipboard = (event: React.MouseEvent, value = '') => {
	copy(value)
	event.preventDefault()
	event.stopPropagation()
	return false
}

export const ContactDetails: React.SFC<ContactDetailsProps> = props => {
	return (
		<ContactDetailsGlam>
			{Object.keys(props).map((type: keyof ContactDetailsProps) => {
				const hoverBackground = type.toUpperCase() as keyof typeof colors
				const defaultBackground = (type.toUpperCase() + '_LIGHT') as keyof typeof colors

				const hoverBackgroundColor = colors[hoverBackground] ? hoverBackground : 'INFO'
				const defaultBackgroundColor = colors[defaultBackground]
					? defaultBackground
					: 'INFO_LIGHT'

				return (
					props[type] &&
					icons[type] &&
					(type === 'email' ? (
						<ContactDetailsButtonWrapper key={type}>
							<Tooltip
								position="top"
								sticky
								html={<TooltipInner>Copy to clipboard</TooltipInner>}>
								<IconNavigationComponents.Link
									href={mapHref(type, props[type]!)}
									target="_blank"
									rel="noopener noreferrer"
									backgroundColors={{
										default: defaultBackgroundColor,
										hover: hoverBackgroundColor,
									}}
									onClick={event => copyToClipboard(event, props[type])}
									css={{ margin: `0 !important`, border: 0 }}>
									<SvgIcon icon={icons[type]} fill={colors.WHITE} />
								</IconNavigationComponents.Link>
							</Tooltip>
						</ContactDetailsButtonWrapper>
					) : (
						<ContactDetailsButtonWrapper key={type}>
							<IconNavigationComponents.Link
								href={mapHref(type, props[type]!)}
								target="_blank"
								rel="noopener noreferrer"
								backgroundColors={{
									default: defaultBackgroundColor,
									hover: hoverBackgroundColor,
								}}
								css={{ margin: `0 !important`, border: 0 }}>
								<SvgIcon icon={icons[type]} fill={colors.WHITE} />
							</IconNavigationComponents.Link>
						</ContactDetailsButtonWrapper>
					))
				)
			})}
		</ContactDetailsGlam>
	)
}
